<template>
    <div class="px-8 mt-4">
      <v-dialog
          v-model="dialogAddItem"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          class="ppmpModal"
          scrollable
        >
          <v-card>
            <v-toolbar
              dark
              color="green darken-2"
              max-height="65"
            >
              <v-toolbar-title>{{ppmpData.BudgetCode}} ({{ppmpData.PPMPTypeID == 1 ? 'DBM' : ppmpData.PPMPTypeID == 2 ? 'Non-DBM' : 'LIB'}})</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="clickCloseMainDialogPR()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="max-height: 900px;">
              <v-row>
              <v-col lg="12" class="">
              <v-alert
                v-model="consolidationDialog"
                type="error"
                outlined
                class="bg mt-5"
                prominent
                v-if="ppmpData.PPMPTypeID == 1 && consolidationData"
              >
                <v-row align="center" class="text-caption">
                  <v-col class="grow">
                    <span>
                      <div class="font-size-16">PR Consolidation</div>
                      Please create your purchase request on DBM items on {{DateStart_consolidation}} or before {{DateEnd_consolidation}}.
                      <div v-if="budgetCodesList.length > 0">
                        <b>List of Allowed Budget Codes:</b>
                      <ul v-for="(item, index) in budgetCodesList" :key="index">
                        <li>{{item}}</li>
                      </ul>
                      </div>
                      </span>
                    </v-col>
                  <v-col class="shrink">
                    <v-icon @click="consolidationDialog = false" color="error">mdi-close</v-icon>
                  </v-col>
                </v-row>
              </v-alert>
                <v-data-table
                  v-model="formData.Lists"
                  :headers="headers"
                  :items="itemList"
                  item-key="ppmpItemID"
                  class="elevation-1 mt-4"
                  disable-pagination
                  hide-default-footer
                  show-select
                  v-if="checkIfAllowed(ppmpData.BudgetCode, ppmpData.PPMPTypeID)"
                >
      <template v-slot:top>
      <v-toolbar flat>

      <v-spacer></v-spacer>
                <v-autocomplete
                  class="mt-2"
                  style="max-width: 300px;"
                    color="green"
                    v-model="formData.CategoryID"
                    item-text="CategoryDescription"
                    item-value="CategoryID"
                    :items="categoryList"
                    dense
                    @change="getItems()"
                    label="Select Category">
                </v-autocomplete>
            <v-btn
              color="green darken-2"
              outlined
              class="mb-2 ml-4"
              @click="PR()"
              :disabled ="!formData.Lists.length"
              :loading="isLoading"
            >
              Purchase Request
            </v-btn>
      </v-toolbar>
    </template>
          <template v-slot:[`item.ItemDescription`]="{ item }">
            <v-edit-dialog :return-value.sync="item.ItemDescription" large>
              {{ item.ItemDescription }}
              <template v-slot:input>
                <v-textarea v-model="item.ItemDescription"> </v-textarea>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.UnitCost`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
          </template>
          <template v-slot:[`item.Amount`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost * item.Qty) }}</span>
          </template>
    </v-data-table>
              </v-col>
               </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="CreatePRDialog"
          persistent
          :max-width="ppmpData.PPMPTypeID == 2 ? '800px' : '450px'"
          scrollable
        >
          <v-card>
            <v-card-title>
              <span class="">Create Purchase Request</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="CreatePRDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px;">
              <v-container>
                <v-form @submit.prevent ref="Formref">
                <v-row>
                    <v-col v-if="getPRTotalAmount() < 300" cols="12" class="pb-0">
                      <v-alert
                      type="error"
                      class="bg"
                      prominent
                      dense
                      ><b>Note:</b> Total amount of PR must be greater than or equal to ₱300.00</v-alert>
                    </v-col>
                    <v-col
                    cols="12">
                    <v-textarea
                    outlined
                    dense
                    color="green"
                      :rules="[formRules.required]"
                      v-model="formData.Purpose"
                      label="Purpose"
                      rows="2"
                      class="pa-0">
                    </v-textarea>
                    </v-col>
                    <v-col cols="12" v-if="ppmpData.PPMPTypeID == 2">
                      <v-alert
                      v-if="other_files"
                      type="warning"
                      class="bg"
                      prominent
                      outlined
                      dense
                      ><b>Note:</b> Other files except PDF was automatically removed on the list.</v-alert>
                      <div class="flex w-full h-screen items-center justify-center text-center">
                        <div class="pa-12 dd-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                          <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".pdf"/>
                          <label for="assetsFieldHandle">
                            <div>
                              Drag and drop your attachments 
                              or <span class="text-decoration-underline font-weight-bold">click here</span> to upload the files (PDF only)
                            </div>
                          </label>
                          <div class="mt-4 text-start" v-if="filelist.length > 0">
                              <v-list
                                subheader
                                three-line
                              >
                                <v-subheader>File List:</v-subheader>

                                <v-list-item v-for="file in filelist" :key="file.name">
                                  <v-list-item-content>
                                    <v-list-item-title>{{file.name}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span v-if="file.size < 1024">
                                        {{formatQty(parseFloat(file.size).toFixed(2))}} byte(s)
                                      </span>
                                      <span v-else-if="file.size < 1048576">
                                        {{(formatQty(parseFloat(file.size) / 1024).toFixed(2))}} KB(s)
                                      </span>
                                      <span v-else>
                                        {{(formatQty(parseFloat(file.size) / 1048576).toFixed(2))}} MB(s)
                                      </span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <span class="text-no-wrap">
                                    <v-btn color="grey" class="mr-1" small dark @click="preview(file)">
                                      view
                                    </v-btn>
                                    <v-btn color="red" small dark @click="remove(filelist.indexOf(file))">
                                      remove
                                    </v-btn>
                                    </span>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                          </div>
                        </div>
                      </div>
                    </v-col>
                </v-row> 
                  </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <strong>Total Amount:&nbsp;&nbsp;&nbsp;&nbsp;</strong>₱{{getPRTotalAmount()}}
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="CreatePRDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-2"
                text
                :loading="isLoading"
                @click="submitPR()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
              <fade-away-message-component
                displayType="variation2"
                v-model="fadeAwayMessage.show" 
                :message="fadeAwayMessage.message" 
                :header="fadeAwayMessage.header" 
                :top="fadeAwayMessage.top"
                :type="fadeAwayMessage.type"
            ></fade-away-message-component>
        </div>
</template>
    
    <script>
      export default {
	props : {
        ppmpData: {},
	},
        data: () => ({
          CreatePRDialog: false,
            dialogAddItem: false,
            formData: {
              CategoryID: null,
              Lists: [],
              Purpose: null
            },
            categoryList: [],
            itemList: [],
                fadeAwayMessage : {
                show : false,
                type : 'success',
                header : 'Successfully Added!',
                message : '',
                top : 10
            },
            headers: [
              {
                text: 'Item Name',
                align: 'start',
                sortable: false,
                value: 'ItemName',
              },
              { text: 'Description', value: 'ItemDescription', sortable: false },
              { text: 'Quantity', value: 'Qty', align: 'center', sortable: false },
              { text: 'Unit Measure', value: 'UnitMeasureDescription', align: 'center', sortable: false},
              { text: 'Unit Cost', value: 'UnitCost', align: 'end', sortable: false},
              { text: 'Amount', value: 'Amount', align: 'end', sortable: false},
            ],
            isLoading: false,
            consolidationData: [],
            DateStart_consolidation: null,
            DateEnd_consolidation: null,
            consolidationDialog: true,
            budgetCodesList: [],
            filelist: [],
            other_files: false,
        }),
       watch: {
            ppmpData: {
            handler (data) {
            if(data.PPMPID){
            this.dialogAddItem = true;
            this.getCategories();
            this.checkConsolidation();
            }
            },
            deep: true,
          },
        },
        methods: {
          submitPR () {
              if(this.$refs.Formref.validate() && parseFloat(this.getPRTotalAmount()) >= 300){
              this.isLoading = true;
             let data = new FormData();

               data.append('PPMPID', this.ppmpData.PPMPID);
               data.append('isDBM', this.ppmpData.isDBM);
               data.append('Purpose', this.formData.Purpose);
               data.append('List', JSON.stringify(this.formData.Lists));
                  this.axiosCall(
                  '/pr/add',
                  'POST',
                  data
              )
              .then((res) => {
                this.uploadFiles(res.data.data.PRID);
                this.isLoading = false;
                this.formData.Purpose = null;
                this.formData.CategoryID = null;
                this.CreatePRDialog = false;
                this.formData.Lists = [];
                this.itemList = [];
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = 'success';
                this.fadeAwayMessage.message = 'Purchase Request was successfully created';
                this.fadeAwayMessage.header = 'System Message';
              })
              }
          },
          uploadFiles(PRID){
            let data = new FormData();
            this.filelist.forEach((item, i) => {
              data.append('files['+i+']', item || '');
            });
            data.append("PRID", PRID);
            this.axiosCall(
                    '/upload/pr/attachment',
                    'POST',
                    data
                )
                .then((res) => {
                    if (res.data.status){
                      // this.getAttachmentsByPRID();
                    }
                    else{
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = res.data.message;
                    this.fadeAwayMessage.header = 'System Message';
                    }
                })
          },
          PR() {
            this.CreatePRDialog = true;
          },
            clickCloseMainDialogPR () {
                this.formData.CategoryID = null;
                this.formData.Lists = [];
                this.categoryList = [];
                this.itemList = [];
                this.dialogAddItem = false;
                this.eventHub.$emit('closePPMPModalPR', false);
            },

          print (data) {
             window.open(this.apiUrl + "/pr/report/" + data.PRID);
          },

          getCategories () {
            let data = new FormData();
               data.append('PPMPID', this.ppmpData.PPMPID);
               data.append('PPMPTypeID', this.ppmpData.PPMPTypeID);
                  this.axiosCall(
                  '/pr/getCategory',
                  'POST',
                  data
              )
              .then((res) => {
                this.categoryList = res.data.data;
              })
          },
          getItems() {
            this.formData.Lists = [];
            let data = new FormData();
               data.append('PPMPID', this.ppmpData.PPMPID);
               data.append('CategoryID', this.formData.CategoryID);
                  this.axiosCall(
                  '/pr/getItems',
                  'POST',
                  data
              )
              .then((res) => {
                this.itemList = res.data.data;
              })
          },
          checkConsolidation(){
          this.consolidationData = null;
                this.axiosCall(
                '/getConsolidationDateSubmission',
                'POST',
                []
            )
            .then((res) => {
              if(res.data.data.result){
                this.consolidationData = res.data.data.result;
                const DateStart_consolidation = new Date(res.data.data.result.DateStart);
                const DateEnd_consolidation = new Date(res.data.data.result.DateEnd);
                const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                const day = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
                this.DateStart_consolidation = month[DateStart_consolidation.getMonth()] + ' ' + DateStart_consolidation.getDate() + ', ' + DateStart_consolidation.getFullYear() + ' (' + day[DateStart_consolidation.getDay()] + ')';
                this.DateEnd_consolidation = month[DateEnd_consolidation.getMonth()] + ' ' + DateEnd_consolidation.getDate() + ', ' + DateEnd_consolidation.getFullYear() + ' (' + day[DateEnd_consolidation.getDay()] + ')';
                // this.DateSubmission_consolidation = month[DateSubmission_consolidation.getMonth()] + ' ' + DateSubmission_consolidation.getDate() + ', ' + DateSubmission_consolidation.getFullYear() + ' (' + day[DateSubmission_consolidation.getDay()] + ')';
                this.budgetCodesList = res.data.data.result.BudgetCodes ? JSON.parse(res.data.data.result.BudgetCodes) : []
              }
            })
          },
          checkIfAllowed(BudgetCode, PPMPTypeID){
            if(this.consolidationData && this.consolidationData.isSelected){
            if(PPMPTypeID == 1){
              let res = false;
              this.budgetCodesList.forEach(item => {
                if(item == BudgetCode){
                  res = true;
                }
              });
              return res;
            }
            else{
              return true;
            }
            }
            else{
              return true;
            }
          },
          onChange() {
          let files = this.$refs.file.files;
          console.log(files)
          let f_files = [...this.filelist];
          this.other_files = false;
          files.forEach(item => {
            if(!this.filelist.find(e => e.name === item.name) && item.type == 'application/pdf'){
              f_files.push(item)
            }
            else{
              this.other_files = true;
            }
          });
          if(f_files.length > 5){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Maximum of 5 PDF files are allowed.";
          }
          else{
            this.filelist = f_files;
          }
          },
          preview(file) {
            window.open(URL.createObjectURL(file));
          },
          remove(i) {
            this.filelist.splice(i, 1);
          },
          dragover(event) {
            event.preventDefault();
            if (!event.currentTarget.classList.contains('bg-green-300')) {
              event.currentTarget.classList.remove('bg-gray-100');
              event.currentTarget.classList.add('bg-green-300');
            }
          },
          dragleave(event) {
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
          },
          drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            if(event.dataTransfer.files.length > 5){
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.header = "System Message";
              this.fadeAwayMessage.message = "Maximum of 5 PDF files are allowed.";
            }
            else{
              this.onChange();
            }
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
          },
          getPRTotalAmount(){
            if(this.formData.Lists){
              let total = 0;
              this.formData.Lists.forEach(item => {
                total = total + (parseFloat(item.Qty) * parseFloat(item.UnitCost));
              });
              return total.toFixed(2);
            }
            else{
              return "0.00"
            }
          }
        },
      }
    </script>
        
    <style scoped>
    .v-dialog--fullscreen, .ppmpModal {
      overflow-x: hidden !important;
    }
    .dd-container {
      background-color: white !important;
      border: 3px dashed green !important;
    }
    </style>